<template>
  <v-card
    :class="{ 'grey lighten-2': dragover }"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
  >
    <v-card-text>
      <h4> {{ fileDetails.description }}</h4>
      <v-row
        class="d-flex flex-column"
        dense
        align="center"
        justify="center"
      >
        <v-icon
          v-if="!fileDetails.isFileUploaded"
          :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
          size="60"
        >
          {{ icons.mdiCloudUpload }}
        </v-icon>
        <v-icon
          v-if="fileDetails.isFileUploaded"
          :class="['fodasse', dragover ? 'mt-2, mb-6' : 'mt-5', labelFlags[fileDetails.uploadStatus]]"
          size="60"
        >
          {{ icons.mdiCheckCircle }}
        </v-icon>
        <p v-if="!fileDetails.isFileUploaded">
          Clica no alfinete e escolhe o ficheiro. <br>Depois, clica na seta para o enviar.
        </p>
      </v-row>
      <v-virtual-scroll
        v-if="uploadedFiles.length > 0"
        :items="uploadedFiles"
        height="150"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
                <span class="ml-3 text--secondary">
                  {{ item.size }} bytes</span>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                @click.stop="removeFile(item.name)"
              >
                <v-icon> {{ icons.mdiCloseCircle }} </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        v-if="approveFuncionalityEnable && fileDetails.isFileUploaded"
        icon
        @click="approveDocument(fileDetails.personId, fileDetails.documentId)"
      >
        <v-icon>
          {{ icons.mdiCheckCircle }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="declineDocument(fileDetails.personId, fileDetails.documentId)"
      >
        <v-icon id="close-button">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
      <v-btn
        v-if="uploadedFiles.length > 0"
        icon
        @click.stop="submit"
      >
        <v-icon id="upload-button">
          {{ icons.mdiUpload }}
        </v-icon>
      </v-btn>

      <v-btn
        v-if="fileDetails.isFileUploaded"
        icon
        @click="previewClicked(fileDetails.personId, fileDetails.documentId)"
      >
        <v-icon id="upload-button">
          {{ icons.mdiEye }}
        </v-icon>
      </v-btn>

      <div>
        <v-file-input
          v-if="!fileDetails.isFileUploaded && uploadedFiles.length == 0"
          id="fileUpload"
          hide-input
          @change="selectFile"
        />
      </div>
      <v-btn
        v-if="fileDetails.isFileUploaded"
        icon
        @click.stop="download(
          fileDetails.personId,
          fileDetails.documentId,
          fileDetails.description)"
      >
        <v-icon id="upload-button">
          {{ icons.mdiDownload }}
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiClose,
  mdiUpload,
  mdiEye,
  mdiCloudUpload,
  mdiCheckCircle,
  mdiCloseCircle,
  mdiDownload,
} from '@mdi/js'

export default {
  components: {},
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    fileDetails: {
      type: Object,
      required: true,
    },
    labelFlags: {
      type: Object,
      required: true,
    },
    approveFuncionalityEnable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: [],
    }
  },
  methods: {
    cleanUploadFiles() {
      this.uploadedFiles = []
    },
    approveDocument(personId, documentId) {
      this.$emit('approveDocument', personId, documentId)
    },
    declineDocument(personId, documentId) {
      // Remove all the uploaded files
      this.uploadedFiles = []

      if (this.fileDetails.isFileUploaded) {
        this.fileDetails.isFileUploaded = false
      }

      // Close the dialog box
      this.$emit('declineDocument', personId, documentId)
    },
    removeFile(fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(
        file => file.name === fileName,
      )

      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1)
    },
    onDrop(e) {
      this.dragover = false

      // If there are already uploaded files remove them
      if (this.uploadedFiles.length > 0) this.uploadedFiles = []

      // If user has uploaded multiple files but the component is not multiple throw error
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        this.$store.dispatch('addNotification', {
          message: 'Only one file can be uploaded at a time..',
          colour: 'error',
        })
      } else {
        // Add each file to the array of uploaded files
        e.dataTransfer.files.forEach(element => this.uploadedFiles.push(element))
      }
    },
    submit() {
      // If there aren't any files to be uploaded throw error
      if (!this.uploadedFiles.length > 0) {
        this.$store.dispatch('addNotification', {
          message: 'There are no files to upload',
          colour: 'error',
        })
      } else {
        // Send uploaded files to parent component
        this.$emit('filesUploaded', this.uploadedFiles, this.fileDetails.documentId)
      }
    },
    selectFile(event) {
      this.uploadedFiles.push(event)
    },
    download(personId, documentId, fileDescription) {
      this.$emit('download-document', personId, documentId, fileDescription)
    },
    previewClicked(personId, documentId) {
      this.$emit('openPreviewDialog', personId, documentId)
    },
  },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiUpload,
        mdiEye,
        mdiCloudUpload,
        mdiCloseCircle,
        mdiDownload,
        mdiCheckCircle,
      },
    }
  },
}
</script>

<style scoped>
.waiting-icon {
  color: rgb(255, 128, 9);
}

.success-icon {
  color: green;
}
</style>
