<template>
  <div>
    <ImagePreviewModal
      :image-url="imagePreviewUrl"
    />
    <v-row>
      <v-col
        v-for="t in filesDetails"
        :id="t.documentId"
        :key="t.documentId"
        md="4"
        cols="12"
      >
        <drag-and-drop
          ref="dragAndDropChild"
          :file-type-id="t.documentId"
          :file-details="t"
          :title="t.description"
          :label-flags="flags"
          :approve-funcionality-enable="approveFuncionalityEnable"
          @filesUploaded="filesUploaded"
          @openPreviewDialog="openPreviewDialog"
          @declineDocument="declineDocument"
          @approveDocument="approveDocument"
          @download-document="downloadDocument"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn
          v-if="downloadAllAvailable"
          color="primary"
          class="me-3 mt-4"
          @click="downloadAllFiles"
        >
          Descarregar Todos Ficheiros
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, inject, provide } from '@vue/composition-api'
import DragAndDrop from './DragAndDrop.vue'
import ImagePreviewModal from '@/layouts/components/Modals/ImagePreviewModal.vue'

let documentsService
let userService

export default {
  components: {
    DragAndDrop,
    ImagePreviewModal,
  },
  props: {
    personId: {
      type: Number,
      required: true,
    },
    personDisplayName: {
      type: String,
      required: true,
    },
    approveFuncionalityEnable: {
      type: Boolean,
      default: false,
    },
    filesDetails: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      flags: {
        Delivered: 'waiting-icon',
        Approved: 'success-icon',
      },
      modalPreviewOpen: true,
      downloadAllAvailable: false,
    }
  },
  watch: {
    filesDetails() {
      this.downloadAllAvailable = true
    },
  },
  created() {
    this.downloadAllAvailable = this.filesDetails
      .some(element => element.uploadStatus !== 'NotDelivered')
  },
  methods: {
    declineDocument(personId, documentId) {
      this.$emit('declineDocument', personId, documentId)
    },
    approveDocument(personId, documentId) {
      documentsService
        .approveDocument(personId, documentId)
        .then(() => {
          this.$emit('refreshDocuments')
        })
        .catch(() => alert('Something went wrong with the approval')) // eslint-disable-line no-alert
    },
    filesUploaded(file, fileType) {
      userService.sendFile(this.personId, file[0], fileType)
        .then(response => {
          if (!response.ok) {
            alert('Error actualizar documentos') // eslint-disable-line no-alert
          } else {
            this.$emit('refreshDocuments')
          }
        })
        .catch(() => {
          alert('Error Actualizar Documents. Tente Novamente') // eslint-disable-line no-alert
        })
    },
  },
  setup(props) {
    const imagePreviewUrl = ref('')
    const dragAndDropChild = ref(null)
    userService = inject('userservice')
    const modalPreviewOpen = ref(false)

    documentsService = inject('documentsservice')

    provide('modal-preview-open', modalPreviewOpen)

    const downloadAllFiles = () => {
      const fileName = props.personDisplayName.replace(' ', '')

      documentsService
        .downloadAllDocuments(props.personId, fileName)
    }

    const openPreviewDialog = (personId, documentId) => {
      imagePreviewUrl.value = `${documentsService.APIURL}/api/Person/${personId}/documents/${documentId}`
      modalPreviewOpen.value = true
    }

    const downloadDocument = (personId, documentId, fileDescription) => {
      const fileName = `${props.personDisplayName.replace(' ', '')}-${fileDescription}`

      documentsService
        .downloadDocument(personId, documentId, fileName)
    }

    return {
      openPreviewDialog,
      downloadAllFiles,
      downloadDocument,
      imagePreviewUrl,
      dragAndDropChild,
      userService,
    }
  },
}
</script>
