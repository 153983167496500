<template>
  <v-dialog
    v-model="dialogOpen"
    max-width="500px"
  >
    <img
      alt="preview"
      :src="imageUrl"
    >
  </v-dialog>
</template>

<script>
import { inject } from '@vue/composition-api'

export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const dialogOpen = inject('modal-preview-open')

    return {
      dialogOpen,
    }
  },
}
</script>
