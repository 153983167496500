<script>
import {
  mdiPencil,
  mdiNotebookPlusOutline,
} from '@mdi/js'
import {
  ref, inject, defineComponent, provide,
} from '@vue/composition-api'
import PlayerProfileModal from '@/layouts/components/Modals/PlayerProfileModal.vue'

let apiService
let apiPlayerService

const data = ref([])
const originalData = ref([])

const selectedItem = ref(null)
const items = ref([])

const headers = ['Selecionar', 'Nome', 'Email', 'Telemóvel', 'Data de Nascimento', 'Idade', 'Escalão', 'Editar']

const profile = ref({})

const modalOpen = ref(false)
const isAprovedClicked = ref(false)
const isUploadedClicked = ref(false)

const deleteAction = () => {
  data.value.forEach(element => {
    //
    if (element.isSelected) {
      // fetch ao serviço
      apiPlayerService
        .deletePlayer(element.id)
        .then(() => {
        // items.value = response.items.ClubLevels.map(clubLevel => clubLevel.description)
          alert(`player deleted:${element.name}`) // eslint-disable-line no-alert
        })
        .catch(error => {
          console.log(error)
        })
    }
  })
}

const filterUpload = () => {
  if (!isUploadedClicked.value) {
    data.value = originalData.value.filter(element => !element.documentsStatus.allUploaded)
    isAprovedClicked.value = false
  } else {
    data.value = originalData.value
  }
  isUploadedClicked.value = !isUploadedClicked.value
}

const filterAproved = () => {
  if (!isAprovedClicked.value) {
    data.value = originalData.value.filter(element => !element.documentsStatus.allAproved)
    isUploadedClicked.value = false
  } else {
    data.value = originalData.value
  }
  isAprovedClicked.value = !isAprovedClicked.value
}

const closeModal = () => {
  modalOpen.value = false
}

function filter(value) {
  const values = originalData.value
  const input = value.toLocaleLowerCase()
  if (input === '' || input === undefined) {
    data.value = values

    return
  }
  data.value = values.filter(element => this.filtervalues(element, input))
}

function compareIngoreCase(source, value) {
  return source.toLocaleLowerCase().indexOf(value) > -1
}

function filtervalues(element, input) {
  return (
    compareIngoreCase(element.name, input)
    || compareIngoreCase(element.clubLevel, input)
    || compareIngoreCase(element.birthDate, input)
  )
}

function getParsedDate(date) {
  return date.substring(0, 10)
}

function mapPlayer(response) {
  return {
    clubLevel: response.clubLevel,
    personId: response.personId,
    id: response.id,
    pictureUrl: response.pictureUrl,
    age: response.age,
    name: response.name,
    gender: response.gender,
    email: response.email,
    phone: response.phone,
    address: response.address,
    birthDate: getParsedDate(response.birthDate),
    playerNumber: response.playerNumber,
    fullName: response.fullName,
    displayName: response.displayName,
    isSelected: false,
    documentsStatus: {
      allAproved: response.documentsStatus.allAproved,
      allUploaded: response.documentsStatus.allUploaded,
    },
  }
}

function logSelectedOption() {
  let uri = ''
  if (selectedItem.value.length === 0) {
    data.value = []
    originalData.value = []

    return
  }

  selectedItem.value.forEach(element => {
    uri += `clubLevels=${element}&`
  })

  apiService
    .get(`api/Player?${uri}`)
    .then(response => {
      const mappedResponse = response.map(element => mapPlayer(element))

      data.value = mappedResponse
      originalData.value = mappedResponse
    })
    .catch(error => {
      console.log(error)
      data.value = []
    })
}

export default defineComponent({
  components: {
    PlayerProfileModal,
  },
  setup() {
    apiService = inject('apiservice')
    apiPlayerService = inject('playerservice')
    const userService = inject('userservice')
    const filtersService = inject('filtersservice')
    provide('dialog-modal2', modalOpen)
    const filesDetails = ref([])

    const getDocuments = personId => {
      userService.getDocuments(personId)
        .then(response => {
          filesDetails.value = response.sort((a, b) => a.documentId - b.documentId)
        })
        .catch(error => {
          console.log(error)
          alert('Something went wrong') // eslint-disable-line no-alert
        })
    }

    const openKitForm = (personId, clubLevel, fullName) => {
      const entry = {
        UserId: String(personId),
        NomeCompleto: fullName,
        Escalao: clubLevel,
      }

      window.open(`https://www.cognitoforms.com/Footback1/EquipamentosEquipas?entry=${JSON.stringify(entry)}`, '_blank')
    }

    const openModal = playerId => {
      profile.value = JSON.parse(JSON.stringify(originalData.value.find(player => player.id === playerId)))
      getDocuments(profile.value.personId)

      modalOpen.value = true
    }
    filtersService.get()
      .then(response => {
        items.value = response.ClubLevels
      })
      .catch(error => {
        console.log(error)
      })

    return {
      icons: {
        mdiPencil,
        mdiNotebookPlusOutline,
      },
      data,
      originalData,
      headers,
      selectedItem,
      items,
      filesDetails,
      filter,
      filtervalues,
      logSelectedOption,
      apiService,
      apiPlayerService,
      profile,
      modalOpen,
      openModal,
      openKitForm,
      closeModal,
      deleteAction,
      filterUpload,
      filterAproved,
      getDocuments,
      isAprovedClicked,
      isUploadedClicked,
    }
  },
})
</script>

<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-text-field
          placeholder="Pesquisar Jogadores"
          outlined
          rounded
          @input="filter"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedItem"
          multiple
          :items="items"
          label="Escolhe o Escalão"
          @change="logSelectedOption"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-btn
          @click="deleteAction"
        >
          Remover
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          :color="(isUploadedClicked ? 'primary' : 'grey darken-4')"
          @click="filterUpload"
        >
          Falta Upload
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          :color="(isAprovedClicked ? 'primary' : 'grey darken-4')"
          @click="filterAproved"
        >
          Falta Aprovar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <VCard>
          <v-simple-table
            :headers="headers"
            item-key="id"
            class="table-rounded"
            hide-default-footer
            disable-sort
            @input="filter"
          >
            <thead>
              <tr>
                <th
                  v-for="header in headers"
                  :id="header"
                  :key="header"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="row in data"
                :id="row.fullName"
                :key="row.id"
              >
                <td>
                  <div class="d-flex flex-row">
                    <div class="p2">
                      <v-checkbox
                        v-model="row.isSelected"
                        hide-details
                      />
                    </div>
                    <div class="p2">
                      <v-btn
                        icon
                        @click="openKitForm(row.personId, row.clubLevel, row.fullName)"
                      >
                        <v-icon> {{ icons.mdiNotebookPlusOutline }} </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold text--primary text-truncate">{{ row.name }}</span>
                    <small>{{ row.post }}</small>
                  </div>
                </td>

                <td v-text="row.email" />
                <td v-text="row.phone" />
                <td v-text="row.birthDate" />
                <td v-text="row.age" />
                <td v-text="row.clubLevel" />
                <td>
                  <v-btn
                    icon
                    @click="openModal(row.id)"
                  >
                    <v-icon>
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </VCard>
      </v-col>
    </v-row>
    <div>
      <PlayerProfileModal
        :profile="profile"
        :open="modalOpen"
        :files-details="filesDetails"
        @refreshDocuments="getDocuments(profile.personId)"
        @close="closeModal"
      ></PlayerProfileModal>
    </div>
  </div>
</template>
