<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
} from '@mdi/js'
import { defineComponent, inject, ref } from '@vue/composition-api'
import DatePicker from '../DatePicker/DatePicker.vue'
import DocumentsSection from '../files/DocumentsSection.vue'

function dateChanged(date) {
  this.profile.birthDate = date
}

export default defineComponent({
  components: {
    DatePicker,
    DocumentsSection,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    filesDetails: {
      type: Array,
      required: true,
    },
  },
  computed: {
    genderRule() {
      return value => value === 'm' || value === 'f' || 'Gender must be either "m" or "f"'
    },
  },
  data() {
    return {
      originalProfile: JSON.parse(JSON.stringify(this.profile)),
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.profile = this.originalProfile
    },
    refreshDocuments() {
      this.$emit('refreshDocuments')
    },
  },
  setup() {
    const dialog = inject('dialog-modal2')
    const playerService = inject('playerservice')
    const documentsService = inject('documentsservice')
    const tab = ref('')

    const tabs = [
      { id: 'personalDetails', title: 'Dados Pessoais', icon: mdiAccountOutline },
      { id: 'files', title: 'Ficheiros', icon: mdiLockOpenOutline },
    ]

    const declineDocument = (personId, documentId) => {
      documentsService
        .declineDocument(personId, documentId)
        .catch(() => alert('Something went wrong with the approval')) // eslint-disable-line no-alert
    }

    const save = profile => {
      playerService.updatePlayer(profile.id, profile)
        .then(() => {
          alert('User updated with sucess') // eslint-disable-line no-alert
          dialog.value = false
        })
        .catch(() => alert('something went wrong')) // eslint-disable-line no-alert
    }

    return {
      dialog,
      tabs,
      tab,
      save,
      dateChanged,
      declineDocument,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
  >
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-card-title>
            <span class="headline">Informação Jogador</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="profile.fullName"
              label="Nome Completo"
            ></v-text-field>
            <v-text-field
              v-model="profile.displayName"
              label="Nome"
            ></v-text-field>
            <v-text-field
              v-model="profile.age"
              label="Idade"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="profile.email"
              label="Email"
            ></v-text-field>
            <v-text-field
              v-model="profile.address"
              label="Morada"
            ></v-text-field>
            <v-text-field
              v-model="profile.gender"
              :rules="[genderRule]"
              label="Gender (m or f)"
            ></v-text-field>
            <v-text-field
              v-model="profile.playerNumber"
              label="Número do Jogador"
              readonly
            ></v-text-field>
            <date-picker
              :label="'Data de Nascimento'"
              :date="profile.birthDate"
              @date-changed="dateChanged"
            ></date-picker>
            <v-text-field
              v-model="profile.phone"
              label="Contacto"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="save(profile)"
            >
              Save
            </v-btn>
            <v-btn
              color="secondary"
              text
              @click="close"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <documents-section
          :person-id="profile.personId"
          :approve-funcionality-enable="true"
          :person-display-name="profile.displayName"
          :files-details="filesDetails"
          @declineDocument="declineDocument"
          @refreshDocuments="refreshDocuments"
        >
        </documents-section>
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>
